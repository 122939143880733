import { handleErrorResponse } from "./ErrorUtil";

// Swagger Client
async function getModel(fn, params, model) {
  try {
    const resp = await fn(params);
    return checkClientResponse(resp, `There was an issue retrieving ${model}`);
  } catch (e) {
    return handleErrorResponse(e);
  }
}

function checkClientResponse(response, error) {
  if (response.ok) {
    return response.obj;
  }
  throw new Error(error);
}

// date formatting helper functions
const modeDateElements = [
  { year: "numeric" },
  { month: "2-digit" },
  { day: "2-digit" },
];
function formatDateForMode(date) {
  function formatDateEl(elMapping) {
    const f = new Intl.DateTimeFormat("en", elMapping);
    return f.format(date);
  }
  return modeDateElements.map(formatDateEl).join("-");
}

function getLocaleDateTime(dateTimeString) {
  const utcDateTime = new Date(dateTimeString);
  const localeDateTime =
    utcDateTime.toLocaleTimeString() + " - " + utcDateTime.toLocaleDateString();
  return localeDateTime;
}

export { getModel, checkClientResponse, formatDateForMode, getLocaleDateTime };
