import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./higher-order/ProtectedRouteHOC";
import Login from "./login/Login";
import Admin from "./admin/Admin";
import Account from "./user/Account";
import Client from "./client/Client";
import ClientJobs from "./client/ClientJobs";
import DataJob from "./data_job/DataJob";

const Router = (props) => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <ProtectedRoute exact path="/" component={Client} />
    <ProtectedRoute exact path="/admin" component={Admin} />
    <ProtectedRoute exact path="/account" component={Account} />
    <ProtectedRoute exact path="/client/:id" component={ClientJobs} />
    <ProtectedRoute exact path="/job/:id" component={DataJob} />
  </Switch>
);

export { Router };
