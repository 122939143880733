import "./index.css";
import "fomantic-ui-css/semantic.css";

import React from "react";
import ReactDOM from "react-dom";
import SwaggerClient from "swagger-client";
import App from "./App";
import { reload } from "./util/AuthUtil";
import reportWebVitals from "./reportWebVitals";

export let client = null;

async function load() {
  client = await new SwaggerClient({
    url: `${process.env.REACT_APP_SWAGGER_ENDPOINT}`,
    v2OperationIdCompatibilityMode: true
  });
  reload();

  ReactDOM.render(<App />, document.getElementById("root"));
}

load();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
