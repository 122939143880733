import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import _ from "lodash";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  Icon,
  List,
  Loader,
  Modal,
  Tab,
  Table,
} from "semantic-ui-react";
import { client } from "../../index";
import { getLocaleDateTime, getModel } from "../../util/DataUtil";

const loadingMessageDict = {
  default: "Loading...",
  updating_cache: "Updating file cache. May take a few minutes.",
  parsing_files: "Job in progress. Try again after job is complete.",
  is_error: "Error occurred during file cache refresh. Please try again.",
};

class NewJobModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loadingMessage: loadingMessageDict["default"],
      isJobSubmitting: false,
      validations: null,
      selectedValidations: null,
    };
  }

  refreshFileCache = () => {
    this.setState(
      { isLoading: true, loadingMessage: loadingMessageDict["updating_cache"] },
      async () => {
        try {
          await client.apis.file.invalidateFileCacheById({
            client_id: this.props.clientId,
          });
          this.validateJob();
        } catch (e) {
          console.error(e);
          this.setState({
            isLoading: false,
            loadingMessage: loadingMessageDict["default"],
          });
        }
      }
    );
  };

  validateJob = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        const resp = await client.apis.file.getFileCacheById({
            client_id: this.props.clientId,
          }),
          selectedValidations = {};

        switch (resp.status) {
          case 200:
            const data = resp.obj;
            if ("jobs" in data) {
              Object.keys(data["jobs"]).forEach((jobName) => {
                selectedValidations[jobName] = {};
                const files = data["jobs"][jobName];
                files.forEach((file) => {
                  selectedValidations[jobName][file["file_id"]] = true;
                });
              });
            }

            this.setState({
              isLoading: false,
              loadingMessage: loadingMessageDict["default"],
              validations: data,
              selectedValidations: selectedValidations,
            });
            break;
          case 202:
            this.setState({
              loadingMessage: loadingMessageDict[resp.obj.client_status],
              isLoading: resp.obj.client_status !== "is_error",
            });
            break;
          default:
            throw new Error();
        }
      } catch {
        this.setState({
          isLoading: false,
          loadingMessage: loadingMessageDict["default"],
          validations: null,
          selectedValidations: null,
        });
      }
    });
  };

  toggleValidation = (jobName, fileId) => {
    let selectedValidations = this.state.selectedValidations;
    selectedValidations[jobName][fileId] =
      !this.state.selectedValidations[jobName][fileId];
    this.setState({
      selectedValidations: selectedValidations,
    });
  };

  toggleValidationAll = (jobName, value) => {
    let selectedValidations = this.state.selectedValidations;
    Object.keys(selectedValidations[jobName]).forEach((fileId) => {
      selectedValidations[jobName][fileId] = value;
    });
    this.setState({
      selectedValidations: selectedValidations,
    });
  };

  submitJob = () => {
    this.setState({ isJobSubmitting: true }, async () => {
      try {
        let selectedValidations = this.state.selectedValidations,
          jobs = {};
        Object.keys(selectedValidations).forEach((jobName) => {
          jobs[jobName] = Object.keys(selectedValidations[jobName]).filter(
            (fileId) => {
              return selectedValidations[jobName][fileId];
            }
          );
        });
        const data = await getModel(
          client.apis.client.queueJobForClientById,
          {
            client_id: this.props.clientId,
            payload: { jobs: jobs },
          },
          "clients"
        );
        this.props.onClose();
        this.props.history.push(`/job/${data.id}`);
      } catch {
        this.setState({
          isJobSubmitting: false,
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.validateJob();
        this.modalRefreshInterval = setInterval(this.validateJob, 2000);
      }
    }
    if (prevState.isLoading !== this.state.isLoading) {
      if (this.state.isLoading) {
        this.modalRefreshInterval = setInterval(this.validateJob, 2000);
      }
    }
    if (!this.state.isLoading || !this.props.open) {
      clearInterval(this.modalRefreshInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.modalRefreshInterval);
  }

  render() {
    return (
      <Modal closeIcon open={this.props.open} onClose={this.props.onClose}>
        <Modal.Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>Start New Job</Grid.Column>

              <Grid.Column width={4}>
                <Button
                  icon
                  onClick={this.refreshFileCache}
                  disabled={this.state.isLoading}
                >
                  <Icon name="refresh" />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {this.state.isLoading ? (
                  <Loader active inline="centered">
                    {this.state.loadingMessage}
                  </Loader>
                ) : this.state.validations !== null ? (
                  this.state.validations["base_folder_exists"] ? (
                    <>
                      {this.state.validations["file_list_cache_date"] && (
                        <p>
                          File list cache last updated at{" "}
                          <i>
                            {getLocaleDateTime(
                              this.state.validations["file_list_cache_date"]
                            )}
                          </i>
                        </p>
                      )}
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Files</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {Object.keys(this.state.validations["jobs"])
                            .sort()
                            .map((jobName) => {
                              const files =
                                this.state.validations["jobs"][jobName].sort();

                              if (
                                files === undefined ||
                                files === null ||
                                files.length === 0
                              ) {
                                return (
                                  <Table.Row key={jobName}>
                                    <Table.Cell>{jobName}</Table.Cell>
                                    <Table.Cell>
                                      No matching files found.
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }

                              let isAllFileSelected = _.union(
                                Object.keys(
                                  this.state.selectedValidations[jobName]
                                ).map((fileId) => {
                                  return this.state.selectedValidations[
                                    jobName
                                  ][fileId];
                                })
                              );
                              if (
                                isAllFileSelected.length > 1 ||
                                isAllFileSelected <= 0
                              ) {
                                isAllFileSelected = false;
                              } else if (isAllFileSelected.length === 1) {
                                isAllFileSelected = isAllFileSelected[0];
                              }

                              return (
                                <Table.Row key={jobName}>
                                  <Table.Cell>{jobName}</Table.Cell>
                                  <Table.Cell>
                                    <List>
                                      <List.Item>
                                        <Checkbox
                                          checked={isAllFileSelected}
                                          label={
                                            isAllFileSelected
                                              ? "De-select all matching files"
                                              : "Select all matching files"
                                          }
                                          onChange={() => {
                                            this.toggleValidationAll(
                                              jobName,
                                              !isAllFileSelected
                                            );
                                          }}
                                        />
                                      </List.Item>
                                      {files.map((file, idx) => {
                                        return (
                                          <List.Item key={idx}>
                                            <Checkbox
                                              checked={
                                                this.state.selectedValidations[
                                                  jobName
                                                ][file["file_id"]]
                                              }
                                              label={file["name"]}
                                              onChange={() => {
                                                this.toggleValidation(
                                                  jobName,
                                                  file["file_id"]
                                                );
                                              }}
                                            />
                                          </List.Item>
                                        );
                                      })}
                                    </List>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                        </Table.Body>
                      </Table>
                    </>
                  ) : (
                    <p>
                      A <code>__slc</code> folder was not detected. Please
                      create and upload files into this folder for processing.
                    </p>
                  )
                ) : (
                  "There was an error"
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>

        <Modal.Actions>
          {this.state.isLoading ? null : (
            <Button
              color="green"
              disabled={this.state.isJobSubmitting}
              onClick={this.submitJob}
            >
              Start Job
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default class ClientJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: null,
      jobs: [],
      isLoading: true,
      isModalOpen: false,
      quicksightUri: null,
      calmReportUrl: null,
      reportQueryParams: {},
    };
  }

  getPage = () => {
    this.setState({ isLoading: true }, async () => {
      const clientData = await getModel(
          client.apis.client.getClientById,
          {
            client_id: this.props.match.params.id,
          },
          "clients"
        ),
        jobs = await getModel(
          client.apis.client.getJobsForClientById,
          {
            client_id: this.props.match.params.id,
          },
          "jobs"
        );
      this.setState({
        client: clientData,
        jobs: jobs,
        isLoading: false,
      });
    });
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  onTabChange = (e, data) => {
    const currentPane = data.panes[data.activeIndex];
    const reportType = currentPane.reportType || null;
    const params = {
      client_id: this.props.match.params.id,
      report_type: reportType,
    };
    this.setState(
      {
        quicksightUri: null,
        calmReportUrl: null,
        reportQueryParams: params,
      },
      async () => {
        if (reportType !== null && !currentPane.extraQueryParamsNeeded) {
          this.updateQuicksightUri();
        }
        this.setCalmReportUrl();
      }
    );
  };

  updateDashboard = async () => {
    if (!this.state.quicksightUri?.EmbedUrl) {
      return;
    }
    let dashboard;
    const containerDiv = document.getElementById("embeddingContainer");
    const options = {
      url: this.state.quicksightUri.EmbedUrl,
      container: containerDiv,
      parameters: {
        Client: this.state.quicksightUri.client_name,
      },
      scrolling: "no",
      height: "1000px",
      width: "1200px",
      footerPaddingEnabled: true,
    };
    dashboard = embedDashboard(options);
  };

  setCalmReportUrl = async () => {
    let calmReportUrl = null;
    calmReportUrl = await getModel(client.apis.client.getDownloadableTable, {
      client_id: this.props.match.params.id,
      downloaded_file: "calm_seat",
    });
    this.setState({ calmReportUrl: calmReportUrl });
  };

  updateQuicksightUri = async () => {
    let quicksightUri = null;
    quicksightUri = await getModel(
      client.apis.client.getReportForClientById,
      this.state.reportQueryParams,
      "jobs"
    );
    this.setState({ quicksightUri: quicksightUri }, async () => {
      await this.updateDashboard();
    });
  };

  addExtraQueryParams = (extraParams) => {
    this.setState(
      {
        reportQueryParams: { ...this.state.reportQueryParams, ...extraParams },
      },
      this.updateQuicksightUri
    );
  };

  componentDidMount() {
    this.getPage();
    window.addEventListener("message", this.handleQuicksightEmbed);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleQuicksightEmbed);
  }

  render() {
    return (
      <Container fluid>
        <Divider hidden />

        <Grid padded="horizontally">
          {this.state.isLoading ? (
            <Grid.Row>
              <Grid.Column>Loading...</Grid.Column>
            </Grid.Row>
          ) : (
            <React.Fragment>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Breadcrumb size="massive">
                    <Breadcrumb.Section>
                      <NavLink exact to={`/`}>
                        All Clients
                      </NavLink>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon="right chevron" />
                    <Breadcrumb.Section>
                      {this.state.client.name}
                    </Breadcrumb.Section>
                  </Breadcrumb>
                </Grid.Column>

                <Grid.Column width={4}>
                  <List>
                    <List.Item>
                      <List.Content floated="right">
                        <Button
                          content={"New Job"}
                          color={"green"}
                          onClick={this.toggleModal}
                        />
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={16}>
                  <Tab
                    menu={{ secondary: true, pointing: true }}
                    onTabChange={this.onTabChange}
                    panes={[
                      {
                        menuItem: "Jobs",
                        render: () => {
                          return (
                            <Grid>
                              <Grid.Row>
                                <Grid.Column>
                                  <Table>
                                    <Table.Header>
                                      <Table.Row>
                                        <Table.HeaderCell width={3}>
                                          Job Id
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                          User
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                          Status
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                          Time Completed
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                      {_.map(
                                        this.state.jobs,
                                        ({
                                          id,
                                          user,
                                          status,
                                          datum,
                                          updated_at,
                                        }) => (
                                          <Table.Row key={id}>
                                            <Table.Cell>
                                              <NavLink exact to={`/job/${id}`}>
                                                {id}
                                              </NavLink>
                                            </Table.Cell>
                                            <Table.Cell>
                                              {user.email}
                                            </Table.Cell>
                                            {status === "in_request_queue" ? (
                                              <Table.Cell>Pending</Table.Cell>
                                            ) : status ===
                                              "in_process_queue" ? (
                                              <Table.Cell warning>
                                                Processing
                                              </Table.Cell>
                                            ) : status === "is_complete" ? (
                                              <Table.Cell positive>
                                                Complete
                                              </Table.Cell>
                                            ) : status === "is_error" ? (
                                              <Table.Cell negative>
                                                Error
                                              </Table.Cell>
                                            ) : null}
                                            {status === "is_complete" ? (
                                              <Table.Cell>
                                                {getLocaleDateTime(updated_at)}
                                              </Table.Cell>
                                            ) : (
                                              <Table.Cell />
                                            )}
                                          </Table.Row>
                                        )
                                      )}
                                    </Table.Body>
                                  </Table>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          );
                        },
                      },
                      {
                        menuItem: "Data dashboard",
                        reportType: "DATA_DASHBOARD",
                        render: () => {
                          if (this.state.quicksightUri !== null) {
                            return (
                              <Grid>
                                <Grid.Row centered>
                                  <Grid.Column width={14}>
                                    <div id="embeddingContainer"></div>
                                  </Grid.Column>
                                </Grid.Row>
                                <a href={this.state.calmReportUrl} download>
                                  <Button>Download CALM table</Button>
                                </a>
                              </Grid>
                            );
                          }
                          return (
                            <Grid>
                              <Grid.Row>
                                <Grid.Column>No report was found</Grid.Column>
                              </Grid.Row>
                            </Grid>
                          );
                        },
                      },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}
        </Grid>

        <NewJobModal
          open={this.state.isModalOpen}
          onClose={this.toggleModal}
          clientId={this.props.match.params.id}
          history={this.props.history}
        />
      </Container>
    );
  }
}
