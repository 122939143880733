import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { isLoggedIn, logout } from "../util/AuthUtil";

const TopNav = withRouter(({ history, location }) => {
  if (isLoggedIn()) {
    return (
      <Menu pointing secondary>
        <NavLink exact className="item" to="/">
          Clients
        </NavLink>

        <Menu.Menu position="right">
          <NavLink exact className="item" to="/account">
            My Account
          </NavLink>
          <Menu.Item
            onClick={async () => {
              await logout();
              history.push("/");
            }}
            name="Log Out"
          />
        </Menu.Menu>
      </Menu>
    );
  }
  return (
    <Menu pointing secondary>
      <Menu.Menu position="right">
        <NavLink exact className="item" to="/login">
          Log In
        </NavLink>
      </Menu.Menu>
    </Menu>
  );
});

export class Header extends Component {
  render() {
    return <TopNav />;
  }
}
