import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./routes/Router";
import { Header } from "./component/Header";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Header />
        <Router />
      </BrowserRouter>
    );
  }
}

export default App;
