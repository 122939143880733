import React, { Component } from "react";
import { client } from "../../index";
import { getModel } from "../../util/DataUtil";
import { Breadcrumb, Container, Divider, Grid, Table } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

const getStatusCell = (status) => {
  switch (status) {
    case "pending":
      return <Table.Cell>PENDING</Table.Cell>;
    case "current":
      return <Table.Cell warning>PROCESSING</Table.Cell>;
    case "complete":
      return <Table.Cell positive>COMPLETE</Table.Cell>;
    case "error":
      return <Table.Cell negative>ERROR</Table.Cell>;
    default:
      return <Table.Cell />;
  }
};

export default class DataJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: null,
      isLoading: true,
      isLoaded: false,
    };
  }

  getPage = () => {
    this.setState({ isLoading: true }, async () => {
      const job = await getModel(
        client.apis.data_job.getJobById,
        {
          data_job_id: this.props.match.params.id,
        },
        "job"
      );
      this.setState({
        job: job,
        isLoading: false,
        isLoaded: true,
      });
      // Clear interval if job is complete
      if (job.status === "is_complete") {
        clearInterval(this.refreshIntervalId);
      }
    });
  };

  componentDidMount() {
    this.refreshIntervalId = setInterval(this.getPage, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshIntervalId);
  }

  render() {
    return (
      <Container fluid>
        <Divider hidden />

        <Grid padded="horizontally">
          {this.state.isLoading && !this.state.isLoaded ? (
            <Grid.Row>
              <Grid.Column>Loading...</Grid.Column>
            </Grid.Row>
          ) : (
            <React.Fragment>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Breadcrumb size="massive">
                    <Breadcrumb.Section>
                      <NavLink exact to={`/`}>
                        All Clients
                      </NavLink>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon="right chevron" />
                    <Breadcrumb.Section>
                      <NavLink exact to={`/client/${this.state.job.client.id}`}>
                        {this.state.job.client.name}
                      </NavLink>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon="right chevron" />
                    <Breadcrumb.Section>{this.state.job.id}</Breadcrumb.Section>
                  </Breadcrumb>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={2}>Job</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                        <Table.HeaderCell>Info</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {"progress" in this.state.job.datum
                        ? Object.keys(this.state.job.datum["progress"])
                            .sort()
                            .map((k) => {
                              const jobProgress =
                                this.state.job.datum["progress"][k];

                              return (
                                <Table.Row key={k}>
                                  <Table.Cell>{k}</Table.Cell>
                                  {getStatusCell(jobProgress["status"])}
                                  {"info" in jobProgress ? (
                                    <Table.Cell>
                                      {jobProgress["info"]}
                                    </Table.Cell>
                                  ) : null}
                                </Table.Row>
                              );
                            })
                        : null}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}
        </Grid>
      </Container>
    );
  }
}
