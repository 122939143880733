import React, { Component } from "react";
import { client } from "../../index";
import { getModel } from "../../util/DataUtil";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  List,
  Table,
} from "semantic-ui-react";
import _ from "lodash";

export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regionFolders: [],
      selectedRegionFolders: {},
      isUpdatingRegionFolder: false,
      dataJobs: [],
      selectedDataJobs: {},
      isUpdatingDataJobs: false,
      reportUrls: {},
      isUpdatingReportUrls: false,
      isLoading: false,
      resetClientID: undefined,
    };
  }

  getPage = () => {
    this.setState({ isLoading: true }, async () => {
      const regionFolders = await getModel(
          client.apis.admin.getAdminBoxRegionFolderList,
          {},
          "region folders"
        ),
        selectedRegionFolders = await getModel(
          client.apis.admin.getAdminBoxRegionFolders,
          {},
          "region folders"
        ),
        dataJobs = await getModel(
          client.apis.admin.getAdminDataJobList,
          {},
          "data jobs"
        ),
        selectedDataJobs = await getModel(
          client.apis.admin.getAdminDataJob,
          {},
          "data jobs"
        ),
        reportUrls = await getModel(
          client.apis.admin.getQuicksightReportUrls,
          {},
          "report urls"
        );

      let selectionRegionFolders = {};
      if ("value" in selectedRegionFolders) {
        selectedRegionFolders["value"].forEach((i) => {
          selectionRegionFolders[i] = true;
        });
      }
      regionFolders.forEach((i) => {
        if (!(i.box_folder_id in selectionRegionFolders)) {
          selectionRegionFolders[i.box_folder_id] = false;
        }
      });

      let selectionDataJobs = {};
      if ("value" in selectedDataJobs) {
        selectedDataJobs["value"].forEach((i) => {
          selectionDataJobs[i] = true;
        });
      }
      dataJobs.forEach((i) => {
        if (!(i in selectionDataJobs)) {
          selectionDataJobs[i] = false;
        }
      });

      let selectedReportUrls = {};
      if ("value" in reportUrls) {
        selectedReportUrls = reportUrls["value"];
      }

      this.setState({
        regionFolders: regionFolders,
        selectedRegionFolders: selectionRegionFolders,
        dataJobs: dataJobs,
        selectedDataJobs: selectionDataJobs,
        reportUrls: selectedReportUrls,
        isLoading: false,
      });
    });
  };

  toggleRegionFolders = (box_folder_id) => {
    let selectedRegionFolders = this.state.selectedRegionFolders;
    selectedRegionFolders[box_folder_id] =
      !this.state.selectedRegionFolders[box_folder_id];
    this.setState({
      selectedRegionFolders: selectedRegionFolders,
    });
  };

  updateRegionFolders = () => {
    this.setState({ isUpdatingRegionFolder: true }, async () => {
      const regionFolders = Object.keys(
        this.state.selectedRegionFolders
      ).filter((i) => {
        return this.state.selectedRegionFolders[i];
      });
      await getModel(
        client.apis.admin.setAdminBoxRegionFolders,
        {
          payload: { value: regionFolders },
        },
        "set region folders"
      );
      this.setState({
        isUpdatingRegionFolder: false,
      });
    });
  };

  resetClient = () => {
    this.setState({ isLoading: true }, async () => {
      console.log("this.state.resetClientID", this.state.resetClientID);
      await getModel(
        client.apis.admin.resetClient,
        {
          payload: { value: this.state.resetClientID },
        },
        "reset client"
      );
      this.setState({
        isLoading: false,
      });
    });
  };

  toggleDataJobs = (dataJob) => {
    let selectedDataJobs = this.state.selectedDataJobs;
    selectedDataJobs[dataJob] = !this.state.selectedDataJobs[dataJob];
    this.setState({
      selectedDataJobs: selectedDataJobs,
    });
  };

  updateDataJobs = () => {
    this.setState({ isUpdatingDataJobs: true }, async () => {
      const dataJobs = Object.keys(this.state.selectedDataJobs).filter((i) => {
        return this.state.selectedDataJobs[i];
      });
      await getModel(
        client.apis.admin.setAdminDataJob,
        {
          payload: { value: dataJobs },
        },
        "set data jobs"
      );
      this.setState({
        isUpdatingDataJobs: false,
      });
    });
  };

  setReportUrls = (e, { value }) => {
    let reportUrls = this.state.reportUrls;
    reportUrls[e.target.name] = value;

    this.setState({
      reportUrls: reportUrls,
    });
  };

  setResetClientID = (e, { value }) => {
    console.log("value", value);

    this.setState({
      resetClientID: value,
    });
  };

  updateReportUrls = () => {
    this.setState({ isUpdatingReportUrls: true }, async () => {
      await getModel(
        client.apis.admin.setQuicksightReportUrls,
        {
          payload: this.state.reportUrls,
        },
        "set report urls"
      );
      this.setState({
        isUpdatingReportUrls: false,
      });
    });
  };

  componentDidMount() {
    this.getPage();
  }

  render() {
    return (
      <Container fluid>
        <Divider hidden />

        <Grid padded="horizontally">
          <Grid.Row>
            <Grid.Column>
              <Header>Administration</Header>
            </Grid.Column>
          </Grid.Row>
          {this.state.isLoading ? (
            <Grid.Row>
              <Grid.Column>Loading...</Grid.Column>
            </Grid.Row>
          ) : (
            <React.Fragment>
              <Grid.Row>
                <Grid.Column width={4}>
                  <List>
                    <List.Item>
                      <Header>Report Url</Header>
                    </List.Item>
                    <List.Item>
                      <Button
                        color="green"
                        disabled={this.state.isUpdatingReportUrls}
                        onClick={this.updateReportUrls}
                      >
                        Save
                      </Button>
                    </List.Item>
                  </List>
                </Grid.Column>

                <Grid.Column width={12}>
                  <Form>
                    <Form.Input
                      name="DATA_DASHBOARD"
                      label="Data dashboard"
                      value={_.get(this.state.reportUrls, "DATA_DASHBOARD", "")}
                      onChange={this.setReportUrls}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <List>
                    <List.Item>
                      <Header>Reset Client</Header>
                    </List.Item>
                    <List.Item>
                      <Button color="green" onClick={this.resetClient}>
                        Save
                      </Button>
                    </List.Item>
                  </List>
                </Grid.Column>

                <Grid.Column width={12}>
                  <Form>
                    <Form.Input
                      label="Client ID"
                      value={this.state.resetClientID || ""}
                      onChange={this.setResetClientID}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <List>
                    <List.Item>
                      <Header>Region Folders</Header>
                    </List.Item>
                    <List.Item>
                      <Button
                        color="green"
                        disabled={this.state.isUpdatingRegionFolder}
                        onClick={this.updateRegionFolders}
                      >
                        Save
                      </Button>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Table>
                    <Table.Body>
                      {this.state.regionFolders.map((k) => {
                        return (
                          <Table.Row key={k.box_folder_id}>
                            <Table.Cell width={1}>
                              <Checkbox
                                checked={
                                  this.state.selectedRegionFolders[
                                    k.box_folder_id
                                  ]
                                }
                                onChange={() => {
                                  this.toggleRegionFolders(k.box_folder_id);
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell>{k.name}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}>
                  <List>
                    <List.Item>
                      <Header>Data Jobs</Header>
                    </List.Item>
                    <List.Item>
                      <Button
                        color="green"
                        disabled={this.state.isUpdatingDataJobs}
                        onClick={this.updateDataJobs}
                      >
                        Save
                      </Button>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Table>
                    <Table.Body>
                      {this.state.dataJobs.map((k) => {
                        return (
                          <Table.Row key={k}>
                            <Table.Cell width={1}>
                              <Checkbox
                                checked={this.state.selectedDataJobs[k]}
                                onChange={() => {
                                  this.toggleDataJobs(k);
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell>{k}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}
        </Grid>
      </Container>
    );
  }
}
