export function handleChange(e, { value }) {
  let key = e.target.name;
  // Edge case for Select elements
  if (key === undefined) {
    key = e.target.parentElement.parentElement.getAttribute("name");
  }
  this.setState({
    [key]: value,
  });
}
