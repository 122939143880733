import React, { Component } from "react";
import {
  Button,
  Container,
  Divider,
  Header,
  Form,
  Grid,
  Message,
} from "semantic-ui-react";

import { client } from "../../index";
import { changePassword } from "../../util/UserUtil";
import { getModel } from "../../util/DataUtil";
import { handleChange } from "../../util/FormUtil";

export default class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      isFormDisabled: false,
      username: "",
      oldPassword: "",
      newPassword: "",
    };
    this.handleChange = handleChange.bind(this);
    this.getEmail();
  }

  getEmail = async () => {
    const { email } = await getModel(
      client.apis.user.getUserFromRequest,
      {},
      "user"
    );
    this.setState({ username: email });
  };

  handleSubmit = async () => {
    this.setState({
      isFormDisabled: true,
    });
    try {
      await changePassword(this.state.oldPassword, this.state.newPassword);
    } catch (e) {
      this.setState({
        isFormDisabled: false,
        error: true,
        errorMessage:
          e.response !== undefined && e.response !== null
            ? e.response.obj.message
            : "There was an issue changing the password",
      });
    }
  };

  render() {
    return (
      <Container>
        <Divider hidden />

        <Grid padded="horizontally">
          <Grid.Row>
            <Grid.Column>
              <Header>Account</Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns="equal">
            <Grid.Column>
              {this.state.error ? (
                <Message negative>{this.state.errorMessage}</Message>
              ) : null}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns="equal">
            <Grid.Column>
              <Form>
                <Form.Input
                  name="username"
                  label="Email Address"
                  type="email"
                  value={this.state.username}
                  disabled
                />

                <Form.Input
                  name="oldPassword"
                  label="Old Password"
                  type="password"
                  value={this.state.oldPassword}
                  onChange={this.handleChange}
                />

                <Form.Input
                  name="newPassword"
                  label="New Password"
                  type="password"
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                />

                <Form.Field
                  control={Button}
                  onClick={this.handleSubmit}
                  disabled={
                    !this.state.username ||
                    !this.state.oldPassword ||
                    !this.state.newPassword ||
                    this.state.isFormDisabled
                  }
                >
                  Change Password
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
