import { client } from "../index";
import { logout } from "./AuthUtil";
import { checkClientResponse } from "./DataUtil";

export async function changePassword(oldPassword, newPassword) {
  const resp = await client.apis.user.editUser({
      payload: {
        password: oldPassword,
        new_password: newPassword,
      },
    }),
    update = checkClientResponse(
      resp,
      "There was an issue changing the password"
    );
  if (update.fields_changed.includes("password")) {
    alert("Password successfully updated. Please log in again.");
    logout();
    window.location.reload();
  }
  return update;
}
