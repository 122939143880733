import React, { Component } from "react";
import { client } from "../../index";
import { getModel } from "../../util/DataUtil";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { Container, Divider, Grid, Header, Table } from "semantic-ui-react";

export default class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      isLoading: false
    };
  }

  getPage = () => {
    this.setState({ isLoading: true }, async () => {
      const data = await getModel(client.apis.client.getClient, {}, "clients");
      this.setState({
        clients: data,
        isLoading: false
      });
    });
  };

  componentDidMount() {
    this.getPage();
  }

  render() {
    return (
      <Container fluid>
        <Divider hidden />

        <Grid padded="horizontally">
          <Grid.Row>
            <Grid.Column>
              <Header>Clients</Header>
            </Grid.Column>
          </Grid.Row>
          {this.state.isLoading ? (
            <Grid.Row>
              <Grid.Column>Loading...</Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Column>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Region</Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {_.map(
                      this.state.clients,
                      ({ id, name, region, datum }) => (
                        <Table.Row key={id}>
                          <Table.Cell>{region}</Table.Cell>
                          <Table.Cell>
                            <NavLink exact to={`/client/${id}`}>
                              {name}
                            </NavLink>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    );
  }
}
