import { logout } from "./AuthUtil";

export function handleErrorResponse(error) {
  const resp = error.response;
  switch (resp.status) {
    case 400:
      // Bad Request
      return null;
    case 401:
      // Unauthorized
      logout();
      window.location.reload(); // redirects to login page after logout
      return null;
    default:
      throw error;
  }
}
